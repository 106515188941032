<template>
  <div>
    <div class="d-flex justify-center mb-2">
      <div class="img-banner-container">
        <img
          v-if="item.banner"
          :src="item.banner"
          alt="imagem da cliente"
          class="img-banner"
        />
        <v-icon v-if="!item.banner" size="300" class="img-banner"
          >mdi-image-size-select-actual</v-icon
        >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="banner-help"
              fab
              dark
              small
              v-on="on"
              color="light blue"
            >
              <v-icon dark> mdi-help </v-icon>
            </v-btn>
          </template>
          <span>Dimensão ideal 430 X 300</span>
        </v-tooltip>
        <v-btn
          class="banner-camera"
          fab
          dark
          small
          color="grey darken-3"
          @click="selecionarImagem()"
        >
          <v-icon dark> mdi-camera-outline </v-icon>
        </v-btn>

        <input
          type="file"
          accept="image/*"
          class="d-none"
          ref="refImagem"
          @change="setImagem($event)"
        />
      </div>
    </div>
    <div class="v-text-field__details pl-2 pr-2 pb-2" >
      <div class="v-messages theme--light error--text" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ mensagemValidacao }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageService from "../service/image-service.js";
const imageService = new ImageService();

export default {
  props: ["item", "mensagemValidacao"],
  methods: {
    async setImagem(event) {
      imageService.getBase64String(event, (callback) => {
        this.$emit("selecionarImagem", callback);
        this.$forceUpdate();
      });
    },
    selecionarImagem() {
      this.$refs.refImagem.click();
    },
  },
};
</script>
<style scoped>
.img-banner-container {
  position: relative;
  width: 100%;
  text-align: center;
}
.img-banner {
  height: 300px;
  max-width: 100%;
}
.banner-camera {
  position: absolute;
  bottom: 12px;
  right: 60px;
}
.banner-help {
  position: absolute;
  bottom: 12px;
  right: 12px;
}
</style>