import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class BannerService extends FetchService {

    listarFiltros() {
        return {
            todos: null,
            titulo: null,
            status: null,
            odernarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Banner",
                valor: "banner",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "banner",
                ordenado: null,
                ocultarResponsivo: true,
                desabilitarOrdenacao: true
            },
            {
                descricao: "Título",
                valor: "titulo",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Status",
                valor: "statusFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarBanners(filtro) {
        const route = `${vue.$globals.endpoint}banner/listarBanners`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarBannersExcel(filtro) {
        const route = `${vue.$globals.endpoint}banner/exportarBannerExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}banner`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}banner`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async listarBannersAtivos(validar = true) {
        const route = `${vue.$globals.endpoint}banner?listaCompleta=false`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route,
            validar
        );
    }
}

export default BannerService;